export interface POI {
    title: string,
    latitude: number,
    longitude: number,
    address: string,
    city: string,
    type: "Hotel" | "Church" | "Party",
    color: "red" | "yellow"
}

export const POIs:POI[] = [
    {
        title: "Hellebæk Kirke",
        latitude: 56.074654,
        longitude: 12.5476396,
        address: "Ved Kirken 4",
        city: "3140 Ålsgårde",
        type: "Church",
        color: "red"
    },
    {
        title: "Borsholm Forsamlingshus",
        latitude: 56.0636995,
        longitude: 12.4824856,
        address: "Skibstrupvej 1",
        city: "3100 Hornbæk",
        type: "Party",
        color: "red"
    },
    {
        title: "Sauntehus Slotshotel",
        latitude: 56.0729218,
        longitude: 12.4865393,
        address: "Saunte Bygade 50",
        city: "3100 Hornbæk",
        type: "Hotel",
        color: "yellow"
    },
    {
        title: "Ewaldsgaarden",
        latitude: 56.088298,
        longitude: 12.4595351,
        address: "Johs. Ewaldsvej 5",
        city: "3100 Hornbæk",
        type: "Hotel",
        color: "yellow"
    },
    {
        title: "Hotel Villa Strand",
        latitude: 56.091946,
        longitude: 12.4477133,
        address: "Kystvej 12",
        city: "3100 Hornbæk",
        type: "Hotel",
        color: "yellow"
    },
    {
        title: "Lille Odinshøj",
        latitude: 56.0757887,
        longitude: 12.5408358,
        address: "Lille Odinshøj 1",
        city: "3140 Ålsgårde",
        type: "Hotel",
        color: "yellow"
    }
];