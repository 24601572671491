import 'mapbox-gl/dist/mapbox-gl.css';
import React, { useState } from 'react';
import ReactMapGL, { ViewportProps, Marker, Popup, NavigationControl } from 'react-map-gl';
import { MapMarker } from "./components/mapMarker";
import { MapMarkerDetail } from "./components/mapMarkerDetail";
import { POI, POIs } from "./components/POIs";

const Maps: React.FC = () => {
    const initialViewPort = {
        width: 600,
        height: 500,
        latitude: 56.075985,
        longitude: 12.5071655,
        zoom: 11,
        bearing: 1,
        pitch: 1,
        altitude: 1,
        maxZoom: 20,
        minZoom: 1,
        maxPitch: 1,
        minPitch: 1,
    };

    const [viewPort, setViewPort] = useState<ViewportProps>(initialViewPort);
    const [selectedPOI, selectPOI] = useState<POI | null>(null);

    return (
        <div>
            <h2>Locations</h2>
            <ReactMapGL
                {...viewPort}
                width={"100%"}
                height={500}
                mapStyle={"mapbox://styles/mapbox/light-v8"}
                onViewportChange={(viewport) => setViewPort(viewport)}
                mapboxApiAccessToken={"pk.eyJ1Ijoia29uc3RhbnR5bmVyIiwiYSI6ImNrNDV3bGJlczBjemQzbHFtcjRuemNnZzQifQ.SAznqhCndYNoyXmigK_lcQ"}
            >
                {POIs.map(POI => {
                    return (
                        <Marker latitude={POI.latitude} longitude={POI.longitude}>
                            <MapMarker size={20} onClick={() => { selectPOI(POI) }} color={POI.color} />
                        </Marker>
                    )
                })}

                {selectedPOI == null ? null : (
                    <Popup
                        tipSize={5}
                        anchor="top"
                        longitude={selectedPOI.longitude}
                        latitude={selectedPOI.latitude}
                        closeOnClick={false}
                        onClose={() => selectPOI(null)}
                    >
                        <MapMarkerDetail info={selectedPOI} />
                    </Popup>
                )}

                <div className="nav" style={{
                    position: 'absolute',
                    top: 36,
                    left: 0,
                    padding: '10px',
                }}>
                    <NavigationControl showCompass={false} />
                </div>
            </ReactMapGL>
        </div>
    );

}

export default Maps;