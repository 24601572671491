import React, { useState, useEffect } from 'react';
import { Dropbox } from "dropbox";
import _ from "lodash";
var dbx = new Dropbox({ accessToken: 'hbBZD2knaHoAAAAAAAE6Q9BpOkwU21eqPf-OcTHMhJrlnFd9WziKFraLxtLpuwzl' });

interface thumbnail {
    path: string;
    thumbnailDetails?: DropboxTypes.files.GetThumbnailBatchResultData;
}

const Images: React.FC = () => {
    const [thumbnails, setThumbnails] = useState<thumbnail[]>([]);
    const [fileList, setFilelist] = useState<DropboxTypes.files.FileMetadataReference[]>([]);


    const [largeImage, setLargeImage] = useState<string | null>(null);
    const [loadingState, setLoadingState] = useState<string>("Load More");

    useEffect(() => {
        getFileList();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        loadThumbnails();
        // eslint-disable-next-line
    }, [thumbnails]);

    const SortFilesByDate = (files: (DropboxTypes.files.FileMetadataReference | DropboxTypes.files.FolderMetadataReference | DropboxTypes.files.DeletedMetadataReference)[]) => {
        (files as DropboxTypes.files.FileMetadataReference[]).sort((a, b) => {
            if (a.server_modified > b.server_modified) {
                return -1;
            }
            if (b.server_modified > a.server_modified) {
                return 1;
            }
            return 0;
        });
    }

    const getFileList = async () => {
        try {
            const result = await dbx.filesListFolder({ path: '', });
            const files = result.entries.filter(entry => entry[".tag"] === "file");
            SortFilesByDate(files);
            const typedFiles = (files as DropboxTypes.files.FileMetadataReference[]);
            setFilelist(typedFiles);
            moveToThumbnailQueue(typedFiles);
        } catch (error) {
            console.log(error);
        }
    }

    const moveToThumbnailQueue = (preloadedFileList?: DropboxTypes.files.FileMetadataReference[]) => {
        try {
            const filesToUse = preloadedFileList ? preloadedFileList : fileList;
            const filesToMove = filesToUse.splice(0, 21);
            if (filesToMove.length > 0) {
                setLoadingState("Loading...");
            }

            setFilelist(filesToUse);

            const files = filesToMove
                .filter(fileToMove => { return fileToMove.path_lower !== undefined })
                .map(fileToMove => {
                    return { path: fileToMove.path_lower === undefined ? "" : fileToMove.path_lower }
                });

            setThumbnails(thumbnails.concat(files));
        } catch (error) {
            console.log(error);
        }
    }

    const loadThumbnails = async () => {
        let allThumbnails = [...thumbnails];
        if (allThumbnails.filter(thumbnail => { return thumbnail.thumbnailDetails === undefined }).length === 0) {
            if (fileList.length === 0) {
                setLoadingState("All loaded");
            }

            return;
        }

        const nextThumbnails = await dbx.filesGetThumbnailBatch({
            entries: _.map(allThumbnails.filter(thumbnail => { return thumbnail.thumbnailDetails === undefined }), image => {
                return {
                    path: image.path !== undefined ? image.path : "",
                    size: { ".tag": "w1024h768" },
                    format: { ".tag": "jpeg" }
                };
            })
        });

        const succeededThumbnails = nextThumbnails.entries.filter(entry => entry[".tag"] === "success");
        succeededThumbnails.forEach(singleThumbnail => {
            const typedThumbnail = (singleThumbnail as DropboxTypes.files.GetThumbnailBatchResultData);

            const newThumbnail = allThumbnails.find(thumbnail => {
                return thumbnail.path === typedThumbnail.metadata.path_lower
            });
            if (newThumbnail !== undefined) {
                newThumbnail.thumbnailDetails = typedThumbnail;
            }
        });

        setThumbnails(allThumbnails);
        setLoadingState("Load More");
    }

    const upload = async (filesFromInputField: FileList | null) => {
        if (filesFromInputField === null) {
            return;
        }
        const newFiles = [];
        for (let i = 0; i < filesFromInputField.length; i++) {
            const file = filesFromInputField[i];
            const image = await dbx.filesUpload({ path: '/' + file.name, contents: file });
            newFiles.push({ path: image.path_lower === undefined ? "" : image.path_lower });

        }

        const newThumbnails = [...thumbnails];
        newFiles.forEach(file => {
            newThumbnails.unshift(file);
        });
        setThumbnails(newThumbnails);
    }

    return (<div>
        <div className="images-header">
            <h1>Photos</h1>
            <div className="upload"><input type="file" onChange={(e) => { upload(e.target.files) }} multiple id="upload-button" /><label htmlFor="upload-button">Upload photos</label></div>
        </div>
        <p>We would be happy if you will share photos from our wedding day here with us</p>
        <div className="image-thumbnails">
            {_.map(thumbnails, thumbnail => {
                return thumbnail.thumbnailDetails === undefined ? null :
                    (<div className="image-container">
                        <img
                            className="single-image"
                            onClick={() => { setLargeImage(thumbnail.thumbnailDetails ? thumbnail.thumbnailDetails.thumbnail : null) }}
                            alt={thumbnail.thumbnailDetails.metadata.name}
                            key={thumbnail.thumbnailDetails.metadata.id}
                            src={`data:image/jpeg;base64,${thumbnail.thumbnailDetails.thumbnail}`} />
                    </div>)
            }
            )}
        </div>
        {largeImage === null ? null : <div className="preview" onClick={() => { setLargeImage(null) }}><img src={`data:image/jpeg;base64,${largeImage}`} alt="" /></div>}
        <div className="load-more-button" onClick={() => { moveToThumbnailQueue() }}>{loadingState}</div>
    </div>
    )
}

export default Images