import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import practicalInfo from './practicalInfo.md';

const PracticalInfo: React.FC = () => {
    const [state, setState] = useState("");

    const getContent = async () => {
        const result = await fetch(practicalInfo);
        setState(await result.text());
    }

    useEffect(() => {
        getContent();
    }, []);

    return (
        <ReactMarkdown source={state} />
    )

}

export default PracticalInfo