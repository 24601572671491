import React from 'react';
import { POI } from "./POIs";

interface MapMarkerDetail {
    info: POI
}

export const MapMarkerDetail: React.FC<MapMarkerDetail> = (props) => {
    return (
        <div>
            <div>
                <p>
                    {props.info.type}: <b>{props.info.title}</b>
                </p>
                <p>{props.info.address}, <br />{props.info.city}</p>
                <p><a href={`https://www.google.com/maps/dir//${props.info.latitude},+${props.info.longitude}`} target="_blank" rel="noopener noreferrer">Navigate to here →</a></p>
            </div>
        </div>
    );
}