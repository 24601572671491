import React, { useState, useEffect } from 'react';
import './App.scss';
import PraticalInfo from "./pages/practicalInfo";
import Accomodation from "./pages/accomodation";
import Maps from "./pages/maps";
import Wishes from "./pages/wishes";
import Images from "./pages/images";
import ReactGA from 'react-ga';
ReactGA.initialize('UA-43042996-6');
ReactGA.pageview(window.location.pathname + window.location.search);

const App: React.FC = () => {
  const [scroll, setScroll] = useState(0);

  const listener = () => {
    setScroll(document.body.getBoundingClientRect().top);
  };

  useEffect(() => {
    window.addEventListener('scroll', listener);
    return () => {
      window.removeEventListener("scroll", listener);
    };
  });

  return (
    <div className="App">
      <div className="dancers-container">
        <div className="dancers"></div>
      </div>
      <div className="main">
        <div className="start-screen page">
          <nav className={scroll < -340 ? "sticky" : "_"}>
            <h1>Cornelia & Oskar</h1>
            <h2 className="date">Saturday, June 19<sup>th</sup> 2021</h2>
            <div className="menu">
              <p className="menu-items"><a href="#practical-info">Practical</a></p>
              <p className="menu-items"><a href="#wishes">Wishes</a></p>
              <p className="menu-items"><a href="#images">Photos</a></p>
            </div>
          </nav>
        </div>
        <div className="text-pages">
          <div className="practical-info page with-content" id="practical-info">
            <div className="content-container">
              <PraticalInfo />
              <Maps />
              <Accomodation />
            </div>
          </div>
          <div className="wishes page with-content" id="wishes">
            <div className="content-container">
              <Wishes />
            </div>
          </div>
          <div className="images page with-content" id="images">
            <div className="content-container">
              <Images />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
